<template>
  <div>
    <a-modal
      title="异常填报"
      :visible="visible"
      width="30%"
      :dialogClass="dialogClass"
      :centered="true"
      :mask="false"
      :destroyOnClose="true"
      :maskClosable="false"
      @cancel="handleCancel"
    >
      <template #footer>
        <a-button type="primary" @click="handleClear" v-if="cameraData"
          >故障清除</a-button
        >
        <a-button type="primary" @click="handleOk" v-if="!cameraData"
          >立即上报</a-button
        >
        <a-button type="primary" @click="handleOk" v-if="cameraData"
          >保存修改</a-button
        >
        <a-button @click="handleCancel">取消</a-button>
      </template>
      <div class="fault-box">
        <div class="fault-left">
          <span class="title">故障类型：</span>
          <div
            class="fault-main"
            :class="item.id == faultTypeKey ? 'fault-main-opt' : ''"
            v-for="(item, key) in faultDetail"
            :key="key"
          >
            <div class="fault-but" @click="handleFaultType(item)">
              <span>{{ item.faultName }}</span>
              <!-- <a-icon 
                  class="img-i" 
                  type="right"
                  :style="{ color: item.id == faultTypeKey ? '#029BE4': '#fff', fontSize: '12px' }"
                   /> -->
            </div>
          </div>
        </div>
        <div class="clear"></div>
        <div class="fault-right">
          <span class="title">具体原因：</span>
          <div class="right-radio">
            <div
              class="right-radio-box"
              :class="item.id == faultIncidentKey ? 'right-radio-box-opt' : ''"
              v-for="(item, key) in faultIncidentList"
              :key="key"
              @click="handleFaultIncident(item)"
            >
              {{ item.faultName }}
            </div>
          </div>
        </div>
        <div class="clear"></div>
        <div class="fault-bottom">
          <span class="title">预计恢复时间：</span>
          <div>
            <a-date-picker
              format="YYYY-MM-DD"
              v-model="timeData"
              :placeholder="this.currentTime"
              :disabled-date="disabledDate"
            />
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import moment from "moment";

export default {
  name: "fault-dialog",
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cameraId: {
      type: String,
      default: "",
    },
    isLucency: {
      type: Boolean,
      default: true,
    },
    cameraData: {
      type: Object,
    },
  },
  data() {
    return {
      faultTypeKey: "",
      faultIncidentKey: "",
      faultIncidentList: [],
      faultNames: "",
      dialogClass: this.isLucency
        ? "fault-modal"
        : "fault-modal fault-lucency-modal",
      timeData: "",
      currentTime: "",
      detailsInfo: {},
      faultNameInfo: "",
      faultDetailData: "",
    };
  },
  computed: {
    ...mapState({
      faultDetail: (state) => state.home.faultDetail,
    }),
  },
  watch: {
    visible(nv) {
      if (nv) {
        this.getDetails();
        setTimeout(() => {
          this.initData();
        }, 1000);
      }
    },
    cameraData(nv) {
      if (nv) {
        this.timeData = this.moment(this.cameraData.restoreTime).format(
          "YYYY-MM-DD"
        );
        // this.detailsInfo.time = res.data?.restoreTime.slice(0, 10);
        this.currentTime = this.timeData;
        console.log("this.timeDatathis.timeData", this.timeData);
        // this.$emit('getDetail')
      } else {
        this.timeData = null;
        this.currentTime = moment().subtract(-1, "days").format("YYYY-MM-DD");
        console.log(
          "this.currentTimethis.currentTimethis.currentTime",
          this.currentTime
        );
      }
    },
  },
  methods: {
    moment,
    disabledDate(current) {
      const date = new Date();
      date.setTime(date.getTime() - 3600 * 1000 * 24);
      return current && current < date;
    },

    initData() {
      this.getFaultDetail().then(() => {
        if (this.faultDetail) {
          if (this.cameraData) {
            this.faultTypeKey = this.cameraData?.parentFaultId;
            this.faultIncidentKey = this.cameraData?.faultId;
            this.faultNameInfo = this.cameraData?.faultName;
            let faultTypeArr = this.disposeFaultType(
              this.faultDetail,
              this.faultTypeKey
            ).childMenus;
            let faultDetailArr = faultTypeArr;
            this.faultIncidentList = faultDetailArr;
            // this.faultIncidentKey = faultDetailArr?.[0]?.id
            // this.faultNames = faultDetailArr?.[0]?.faultName
            console.log(
              "this.faultTypeKey",
              faultTypeArr,
              this.faultIncidentList,
              this.cameraData
            );
          } else {
            let faultTypeArr = this.faultDetail?.[0];
            this.faultTypeKey = faultTypeArr?.id;
            let faultDetailArr = faultTypeArr?.childMenus;
            this.faultIncidentList = faultDetailArr;
            this.faultIncidentKey = faultDetailArr?.[0]?.id;
            this.faultNames = faultDetailArr?.[0]?.faultName;
            console.log(
              "this.faultTypeKey1",
              faultTypeArr,
              this.faultIncidentList,
              this.faultDetail
            );
          }
        }
      });
    },
    // 获取故障明细
    disposeFaultType(list, id) {
      console.log("disposeFaultType获取故障明细", list, id);
      if (!list.length) return [];
      let arr = [];
      list.map((ele) => {
        if (ele.id === id) {
          arr = ele;
        }
      });
      return arr;
    },
    handleFaultType(item) {
      this.faultTypeKey = item.id;
      if (item.childMenus?.length > 0) {
        this.faultIncidentList = item.childMenus || [];
        this.faultIncidentKey = item.childMenus?.[0].id;
        this.faultNames = item.childMenus?.[0].faultName;
      } else {
        this.faultIncidentList = [];
        this.faultIncidentKey = "";
        this.faultNames = item.faultName;
      }
    },
    handleFaultIncident(item) {
      this.faultIncidentKey = item.id;
      this.faultNames = item.faultName;
    },
    handleCancel() {
      this.$emit("closeFault");
      this.timeData = null;
    },
    handleOk() {
      let params = {
        cameraFaultList: [
          {
            cameraId: this.cameraId,
            createId: this.faultTypeKey,
            faultId: this.faultIncidentKey || this.faultTypeKey,
            faultName: this.faultNames || this.faultNameInfo,
            // restoreTime: this.moment(this.timeData).format('YYYY-MM-DD')
            restoreTime: this.timeData
              ? moment(this.timeData).format("YYYY-MM-DD")
              : moment().subtract(-1, "days").format("YYYY-MM-DD"), //预计恢复时间
          },
        ],
      };
      this.$api.home.faultReported(params).then((data) => {
        if (data.code === 200) {
          this.$message.success("异常填报成功！");
          // this.getFaultDetails(this.cameraId)
          this.$emit("getDetail");
          this.$emit("faultClick");
          // this.$route.replace({ path: '/login' })
          this.timeData = "";
          this.handleCancel();
        } else {
          this.$message.error(data.message);
          this.handleCancel();
        }
      });
    },
    handleClear() {
      let params = {
        cameraFaultList: [
          {
            cameraId: this.cameraId,
            createId: this.faultDetailData.createId,
            faultId: this.faultDetailData.faultId,
            faultName: this.faultDetailData.faultName,
            restoreTime: this.moment(new Date()).format("YYYY-MM-DD HH:mm"),
          },
        ],
      };
      this.$api.home.faultReported(params).then((data) => {
        if (data.code === 200) {
          this.$message.success("修改成功！");
          this.$nextTick(() => {
            this.$emit("getDetail");
            // this.$emit('faultClick');
          });
          this.handleCancel();
        } else {
          this.$message.error(data.message);
          // this.handleCancel()
        }
      });
    },
    getDetails() {
      let params = {
        cameraId: this.cameraId,
      };
      this.$api.home.faultDetails(params).then((res) => {
        console.log(res);
        if (res.code === 200) {
          this.faultDetailData = res.data;
          this.timeData = this.moment(res.data?.restoreTime).format(
            "YYYY-MM-DD"
          );
          console.log("faultDetail", this.faultDetail);
        } else {
          this.$message.error(res.message);
        }
      });
    },
    ...mapActions(["getFaultDetail"]),
  },
  mounted() {
    if (!this.cameraData) {
      this.currentTime = moment().subtract(-1, "days").format("YYYY-MM-DD");
    }
    // this.initData();
  },
};
</script>
<style lang="less">
.fault-lucency-modal {
  &.ant-modal {
    .ant-modal-content {
      // background: pink;
      background: rgba(30, 40, 60, 1);
    }
  }
}
.fault-modal {
  &.ant-modal {
    .ant-modal-body {
      padding: 0;
    }
    .ant-modal-footer {
      display: flex;
      justify-content: center;
    }
  }
  .fault-box {
    height: 300px;
    padding-left: 20px;
    .clear {
      clear: both;
    }
    .title {
      float: left;
      height: 60px;
      line-height: 40px;
    }
    .fault-left {
      // display: block;
      .fault-main {
        float: left;
        display: block;
        text-align: center;
        height: 30px;
        line-height: 30px;
        width: 90px;
        border-radius: 40px;
        border: 1px solid #3683d4;
        margin: 5px;
        &.fault-main-opt {
          // border-left: 5px solid #029BE4;
          color: #fff;
          background: #409eff;
        }
      }
      .fault-but {
        position: relative;
        display: inline-block;
        text-align: center;
        height: 100%;
        cursor: pointer;
        span {
          display: inline-block;
          max-width: 65px;
          color: #fff;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        .img-i {
          position: absolute;
          right: -10px;
          top: 50%;
          transform: translateY(-50%);
          width: 10px;
          height: 10px;
          // background: #fff;
        }
      }
    }
    .fault-right {
      padding: 10px 0;
      .right-radio {
        .right-radio-box {
          display: inline-block;
          width: 140px;
          height: 30px;
          line-height: 30px;
          color: #fff;
          font-size: 14px;
          text-align: center;
          padding: 0 10px;
          margin: 0 15px 15px 0;
          box-sizing: border-box;
          cursor: pointer;
          border: 1.5px solid #3683d4;
          border-radius: 5px;
          &.right-radio-box-opt {
            background: #409eff;
            border: 1.5px solid #409eff;
          }
        }
      }
    }
  }
}
</style>
